import { useCallback, useContext, useMemo } from "react";
import ArrowLeft from "../../assets/PetPage/arrow_left_dashed_small.svg";
import ArrowRight from "../../assets/PetPage/arrow_right_dashed.svg";
import PandaMain from "../../assets/UserInfo/panda-logo-main.svg";
import { Pagination, PaginationItem } from "@mui/material";
import { ReactComponent as ArrowLeftRed } from "../../assets/iconsSmall/arrow_left_solid_red.svg";
import { ReactComponent as ArrowRightRed } from "../../assets/iconsSmall/arrow_right_solid_red.svg";
import { TileTableContext } from "../../config/context";
import { t } from "i18next";
import { getCurrencyForTotal, getTotalAmount } from "../../config/helpers";

export const TileRow = ({ children, label, red, changed }) => (
  <div className="policy-table-mobile__row">
    <div className="policy-table-mobile__left">{label}</div>
    <div
      className={`policy-table-mobile__right ${red ? "CellAmountRed" : ""}`}
      style={{
        backgroundColor: changed ? "var(--b-color-additional-1)" : "#FCE7D2",
        color: changed ? "var(--b-color-bg-main-2)" : "#312f2f",
      }}>
      {children}
    </div>
  </div>
);

export const TileTotalRow = ({ subTotal, total, label }) => (
  <div className="policy-table-mobile__row">
    <div className="policy-table-mobile__left total">{label}</div>
    <div className="policy-table-mobile__right-high">
      <div className="policy-table-mobile__right total" style={{ fontWeight: "500" }}>
        <div>{subTotal}</div>
        <div>{t("netto")}</div>
      </div>
      <div className="policy-table-mobile__right total">
        <div>{total}</div>
        <div>{t("totalAmount2")}</div>
      </div>
    </div>
  </div>
);

export const TileInfoRow = ({ label }) => (
  <div className="policy-table-mobile__info">
    {label}
  </div>
);

export const TileContent = ({ data, showResultTable }) => {
  const { currentClaim } = useContext(TileTableContext);
  const index = useMemo(() => currentClaim - 1, [currentClaim]);
  const row = useMemo(() => data[index], [index, data]);

  return (
    <>
      <TileRow label={t("date")} changed={row?.IsNewLine || row?.changedValues?.Date}>{row?.changedValues?.Date || row?.Date || t("noInfo")}</TileRow>
      <TileRow label={t("desc")} changed={row?.IsNewLine || row?.changedValues?.Description}>{row?.changedValues?.Description || row?.Description || t("noInfo")}</TileRow>
      {/* {mode === USER_MODE.ADMIN && (
        <TileRow label={t("got")}>{row?.GOT || t("noInfo")}</TileRow>
      )} */}
      <TileRow label={t("quantity2")} changed={row?.IsNewLine || row?.changedValues?.Quantity}>
        {row?.changedValues?.Quantity || row?.Quantity || t("noInfo")}
      </TileRow>
      <TileRow label={t("unitPrice")} red changed={row?.IsNewLine || row?.changedValues?.UnitPrice}>
        {(row?.changedValues?.UnitPrice || row?.UnitPrice) + (row?.Currency == "$" ? " $" : " €") || t("noInfo")}
      </TileRow>
      <TileRow label={t("tax")} red changed={row?.IsNewLine || row?.changedValues?.Tax}>
        {(row?.changedValues?.Tax || row?.Tax) + " %" || t("noInfo")}
      </TileRow>
      <TileRow label={t("Amount")} red changed={row?.IsNewLine || row?.changedValues?.Amount}>
        {(row?.changedValues?.Amount || row?.Amount) + (row?.Currency == "$" ? " $" : " €") || t("noInfo")}
      </TileRow>
      <TileTotalRow
        label={t("subInvTotalMob")}
        subTotal={getTotalAmount(data, true) + " " + getCurrencyForTotal(data)}
        total={getTotalAmount(data) + " " + getCurrencyForTotal(data)}
      />
      {showResultTable && <TileInfoRow label={t("customerChanged")}></TileInfoRow>}
    </>
  );
};

export default function TileTable({
  data,
  currentClaim,
  setCurrentClaim,
  children
}) {

  const handlePage = useCallback((event, value) => {
    setCurrentClaim(value);
  }, []);

  const decreasePageNumber = () => {
    if (currentClaim > 1) {
      setCurrentClaim(currentClaim - 1);
    }
  };

  const increasePageNumber = () => {
    if (currentClaim < data.length) {
      setCurrentClaim(currentClaim + 1);
    }
  };

  return (
    <div className="SlowShow policy-table-mobile">
      <div className="policy-table-mobile__header">
        <div
          className="policy-table-mobile__back"
          style={currentClaim < 2 ? { opacity: "0.5" } : {}}
        >
          <div
            className="button_red_mobile back margin-0"
            onClick={decreasePageNumber}
          >
            <img src={ArrowLeft} alt="ArrowLeft" />
          </div>
        </div>
        <div className="policy-table-mobile__img">
          <img
            src={PandaMain}
            alt="Panda_Main_Logo"
            className="main-logo-policy__mobile"
          />
        </div>
        <div
          className="policy-table-mobile__next"
          style={currentClaim == data.length ? { opacity: "0.5" } : {}}
        >
          <div
            className="button_red_mobile back margin-0"
            onClick={increasePageNumber}
          >
            <img src={ArrowRight} alt="ArrowRight" />
          </div>
        </div>
      </div>
      <TileTableContext.Provider value={{ currentClaim }}>
        {children}
      </TileTableContext.Provider>
      {data.length > 1 && (
        <div className="policy-table-mobile__pagination">
          <Pagination
            className="reset-pagination"
            count={data.length}
            page={currentClaim}
            siblingCount={1}
            boundaryCount={1}
            onChange={handlePage}
            renderItem={(item) => (
              <PaginationItem
                slots={{ previous: ArrowLeftRed, next: ArrowRightRed }}
                {...item}
              />
            )}
          />
        </div>
      )}
    </div>
  );
}
