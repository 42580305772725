import uuidv4 from "react-uuid";
import { toast } from "react-toastify";
import FlagEN from "../assets/images/united-kingdom.png";
import FlagGE from "../assets/images/germany.png";
import { CURRENCY, LANGUAGES } from "../constants/ApplicationConstants";
import moment from "moment";
import { checkLastClaim } from "../services/clams";

export const assignUniqueKey = (steps) => {
  if (Array.isArray(steps)) {
    steps = steps.map((i) => {
      if (Array.isArray(i)) return assignUniqueKey(i);
      if (i.content) {
        i.content = assignUniqueKey(i.content);
      }
      return { ...i, uniqueId: i.uniqueId ? i.uniqueId : uuidv4() };
    });
  }
  return steps;
};

export const getStepTitles = (steps) => {
  let titles = [];
  if (Array.isArray(steps)) {
    steps = steps.forEach((i) => {
      if (i.code === "mcq") {
        i.content.forEach((j) => {
          titles = [...titles, ...getStepTitles(j.content)];
        });
      } else titles.push(i.title?.trim());
    });
  }
  return titles;
};

export const setStyleAttribute = (attr, val) => {
  const root = document.documentElement;
  root.style.setProperty(attr, val);
};

export const german = {
  name: "german",
  months: [
    ["Januar", "Jan"],
    ["Februar", "Feb"],
    ["März", "Mär"],
    ["April", "Apr"],
    ["Mai", "Mai"],
    ["Juni", "Jun"],
    ["Juli", "Jul"],
    ["August", "Aug"],
    ["September", "Sep"],
    ["Oktober", "Okt"],
    ["November", "Nov"],
    ["Dezember", "Dez"],
  ],
  weekDays: [
    ["Samstag", "Sa"],
    ["Sonntag", "So"],
    ["Montag", "Mo"],
    ["Dienstag", "Di"],
    ["Mittwoch", "Mi"],
    ["Donnerstag", "Do"],
    ["Freitag", "Fr"],
  ],
  digits: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"],
  meridiems: [
    ["vormittags", "AM"],
    ["nachmittags", "PM"],
  ],
};

export const claimsTableHeaders = [
  {
    title: "invoiceDate",
    sort: true,
  },
  {
    title: "claimNo",
    sort: true,
  },
  {
    title: "diagnosis",
    sort: true,
  },
  {
    title: "veterinarian",
    sort: true,
  },
  {
    title: "Status",
    sort: true,
  },
  {
    title: "Details",
    sort: false,
  },
];

export const claimsTableHeadersAdmin = [
  {
    title: "invoiceDate",
    sort: true,
  },
  {
    title: "claimNo",
    sort: true,
  },
  {
    title: "diagnosis",
    sort: true,
  },
  {
    title: "petName",
    sort: true,
  },
  {
    title: "veterinarian",
    sort: true,
  },
  {
    title: "Status",
    sort: true,
  },
  {
    title: "Details",
    sort: false,
  },
];

export const invoiceTableHeaders = [
  {
    title: "TreatmentDate",
    sort: true,
  },
  {
    title: "desc",
    sort: false,
  },
  {
    title: "got",
    sort: false,
  },
  {
    title: "quantity2",
    sort: false,
  },
  {
    title: "netto",
    sort: false,
  },
  {
    title: "tax",
    sort: false,
  },
  {
    title: "totalAmount2",
    sort: false,
  },
];

export const existingClaimsHeaders = [
  {
    title: "invoiceDate",
    sort: true,
  },
  {
    title: "claim",
    sort: true,
  },
  {
    title: "veterinarian",
    sort: true,
  },
  {
    title: "Status",
    sort: true,
  },
  {
    title: "Amount",
    sort: true,
  },
  {
    title: "Details",
    sort: false,
  },
];

export const paymentHeaders = [
  {
    title: "invoiceDate",
    sort: true,
  },
  {
    title: "policyID",
    sort: true,
  },
  {
    title: "claimNo",
    sort: true,
  },
  {
    title: "customer",
    sort: true,
  },
  {
    title: "Amount",
    sort: true,
  },
  {
    title: "linkToInsly",
    sort: false,
  },
];

export const refundHeaders = [
  {
    title: "policyNo",
    sort: true,
  },
  {
    title: "iban",
    sort: true,
  },
  {
    title: "Amount",
    sort: true,
  },
  {
    title: "event",
    sort: false,
  },
  {
    title: "hasOverdue",
    sort: true,
  },
];

export const showToast = (
  message,
  { theme = "light", position = "top-right", error = false } = {}
) => {
  error
    ? toast.error(message, {
      position: position,
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: theme,
    })
    : toast.success(message, {
      position: position,
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: theme,
    });
};

export const getLangIcon = (lang) => {
  switch (lang) {
    case LANGUAGES.EN:
      return FlagEN;
    case LANGUAGES.DE:
      return FlagGE;
    default:
      return "";
  }
};

export const getDate = (date_raw) => {
  return date_raw.split("T")[0];
};

export const formatAnyDate = (inputDateString) => {
  const dateString = inputDateString.split("T")[0];
  const possibleInputFormats = [
    "DD-MM-YYYY",
    "DD.MM.YYYY",
    "DD.MM.YY",
    "MM/DD/YYYY",
    "MM.DD.YYYY",
    "MM/DD/YY",
    "YYYY-MM-DD",
    "YYYY.MM.DD",
    "YY.MM.DD",
    "DD/MM/YYYY",
    "DD/MM/YY",
    "YYYY/MM/DD",
    "YY/MM/DD",
    "DD MM YYYY",
    "DD MM YY",
    "YYYY MM DD",
    "YY MM DD"
  ];

  let parsedDate = null;
  for (let format of possibleInputFormats) {
    parsedDate = moment(dateString, format, true);
    if (parsedDate.isValid()) {
      break;
    }
  }

  if (parsedDate && parsedDate.isValid()) {
    const formattedDate = parsedDate.format("DD.MM.YYYY");
    return formattedDate;
  } else {
    return "";
  }
}

export const getDateGE = (inputDateTime, onlyDate = false) => {
  const now = new Date();
  const localTimezoneOffsetInMinutes = now.getTimezoneOffset();

  if (typeof inputDateTime === 'string') {
    const utcMoment = moment.utc(inputDateTime, ["YYYY-MM-DD HH:mm:ss", "DD-MM-YYYY"], true);

    if (utcMoment.isValid()) {
      const localMoment = utcMoment.local().utcOffset(-localTimezoneOffsetInMinutes);
      const formattedDateTime = onlyDate ? localMoment.format("DD.MM.YYYY") : localMoment.format("DD.MM.YYYY HH:mm:ss");
      return formattedDateTime;
    }
  }

  return inputDateTime;
};

export const formatTableDate = (dateString) => {
  if (!dateString) return null;

  const formats = ['YYYY-MM-DD', 'DD-MM-YYYY', 'DD.MM.YYYY', 'DD/MM/YYYY'];

  for (const format of formats) {
    if (moment(dateString, format, true).isValid()) {
      return moment(dateString, format).format('YYYY-MM-DD');
    }
  }

  return null;
};

export const incorrectDateRangePicked = (date, dateTwo) => {
  const date2 = moment(dateTwo);
  const date1 = moment(date);
  if (!date1.isValid() || !date2.isValid()) return true;
  else return date1.isSameOrAfter(date2);
};

// "reduceTotalAmount" and "getCurrencyFromInvoice" for Existing claim total calculations

export const reduceTotalAmount = (row) => {
  const tableDetails = row?.data;

  if (!tableDetails) return null;

  const amount = tableDetails.reduce((acc, val) => {
    return acc + +val?.amount;
  }, 0);

  return amount.toFixed(2);
};

export const getCurrencyFromInvoice = (row) => {
  const details = row?.data[0]?.currency;

  if (!details) return CURRENCY.EUR;

  return CURRENCY[details?.payment?.currencySymbol] || CURRENCY.EUR;
};

// "getTotalAmount" and "getCurrencyForTotal" for Invoice table total calculations

export const getTotalAmount = (tableData, sub = false) => {
  if (!tableData) return null;

  const amount = tableData.reduce((acc, el) => {
    const valueToSum = sub
      ? (el?.changedValues?.UnitPrice || el?.UnitPrice)
      : (el?.changedValues?.Amount || el?.Amount);
    return acc + +valueToSum;
  }, 0);

  return amount.toFixed(2);
};

export const getCurrencyForTotal = (tableData) => {
  const details = tableData[0]?.Currency;

  if (!details) return CURRENCY.EUR;

  return CURRENCY[details] || CURRENCY.EUR;
};

export const isFlowExistingClaim = (searchParams) => {

  return searchParams.get("type") === "Existing Claim";
}

export async function checkLastClaimRecursively(id, attempt = 0) {
  const maxAttempts = 3;
  if (attempt >= maxAttempts) {
    return true
  }

  const { data: isLastClaimResolved } = await checkLastClaim(id);

  if (isLastClaimResolved) {
    return isLastClaimResolved;
  } else {
    await new Promise(resolve => setTimeout(resolve, 2000));
    return checkLastClaimRecursively(id, attempt + 1);
  }
}

export const getSettedTheme = () => {
  const bodyTheme = document.body.getAttribute('data-theme');
  return bodyTheme
}

export function compareData(InitialData, ResultData) {
  return ResultData.map(resultObj => {
    const initialObj = InitialData.find(initialObj => resultObj.IdToCompare === initialObj.IdToCompare);

    if (!initialObj) {
      return { ...resultObj, IsNewLine: true };
    } else {

      const editedFields = Object.keys(resultObj).filter(key => !isEqual(resultObj[key], initialObj[key]));

      const modifiedInitialData = editedFields.reduce((acc, key) => {
        acc[key] = initialObj[key];
        return acc;
      }, {});

      return {
        ...resultObj,
        IsNewLine: resultObj?.IsNewLine || !!editedFields.length || false,
        initialData: modifiedInitialData
      };
    }
  });
}


/**
 * Recursively renames keys in an object.
 *
 * @param {object} obj - The object to be processed.
 * @param {object} keyMap - An object defining the keys to be renamed and their new names.
 * @returns {object} - Object with renamed keys.
 */
export function renameDropdownIdtoId(obj, keyMap = { formulaId: 'id' }) {
  if (!obj || typeof obj !== 'object') return obj;

  if (Array.isArray(obj)) {
    return obj.map(item => renameDropdownIdtoId(item, keyMap));
  }

  return Object.keys(obj).reduce((acc, key) => {
    const newKey = keyMap[key] || key;
    acc[newKey] = renameDropdownIdtoId(obj[key], keyMap);
    return acc;
  }, {});
}

export function isEqual(obj1, obj2) {
  // Deep equality check for objects
  return JSON.stringify(obj1) === JSON.stringify(obj2);
}

export function formatNumber(num) {
  // Check if the number has a fractional part
  return num % 1 === 0 ? num.toString() : num.toFixed(2);
}
