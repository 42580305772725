import { useCallback, useState } from "react";
import { Request } from "../config/requests";
import { getToken } from "../config/token";
import { renameDropdownIdtoId } from "../config/helpers";

export default function useFlowById() {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const refetch = useCallback(async (flow_id) => {
    try {
      setIsLoading(true);
      const res = await Request({
        method: "get",
        token: getToken(),
        route: `api/flows/${flow_id}`,
      });
      const data = await res.json();
      setData(data);
      return renameDropdownIdtoId(data);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  }, []);

  return [refetch, data, isLoading];
}
